import axios from 'axios';
import getApiUrl from '../../helpers/apiUrls'
import { authHeader } from '../../helpers/authHeader';
import utilCommon from '../../helpers/utilCommon';
import * as commonActions from '../../store/common/actions';
import * as type from '../../store/applicationSettings/actionTypes';
import * as globals from '../../helpers/enums';
import lodash from 'lodash';

export const addTransitTime = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('applicationSettings', 'transitTime');
    dispatch(commonActions.setLoader(true))
    axios[inputDTO.id ? 'put' : 'post'](url, inputDTO, params)
        .then((response) => {
            if (response.data.success) {
                if (inputDTO.id) {
                    utilCommon.showNotification('Transit time updated succesfully.', dictionary, lang)
                } else {
                    utilCommon.showNotification('Transit time added succesfully.', dictionary, lang)
                }
                dispatch({ type: type.SET_PAGING })
                getTransitTime(dispatch, lang, dictionary, { sortColumn: 'name', sortOrder: 'asc', pageNumber: 1, pageSize: 50 })
            } else {
                dispatch(commonActions.setLoader(false))
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};


export const addFuelSurcharge = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('applicationSettings', 'fuelSurcharge');
    dispatch(commonActions.setLoader(true))
    axios[inputDTO.id ? 'put' : 'post'](url, inputDTO, params)
        .then((response) => {
            if (response.data.success) {
                if (inputDTO.id) {
                    utilCommon.showNotification('Fuel surcharge index updated succesfully.', dictionary, lang)
                } else {
                    utilCommon.showNotification('Fuel surcharge index added succesfully.', dictionary, lang)
                }
                dispatch({ type: type.SET_PAGING })
                getFuelSurcharge(dispatch, lang, dictionary, { sortColumn: 'name', sortOrder: 'asc', pageNumber: 1, pageSize: 50 })
                if (inputDTO.id) {
                    getFuelCostIndex(dispatch, lang, dictionary, { fuelSurchargeId: inputDTO.id })
                } else {
                    getFuelCostIndex(dispatch, lang, dictionary, {
                        fuelSurchargeId: response.data.content.lastInsertedId
                    })
                }
            } else {
                dispatch(commonActions.setLoader(false))
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const deleteFuelSurcharge = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('applicationSettings', 'fuelSurcharge');
    let payload = { id: [inputDTO.id] }
    dispatch(commonActions.setLoader(true))
    axios.patch(url, payload, params)
        .then((response) => {
            if (response.data.success) {
                utilCommon.showNotification('Fuel surcharge index deleted succesfully.', dictionary, lang)
                dispatch({ type: type.SET_PAGING })
                getFuelSurcharge(dispatch, lang, dictionary, { sortColumn: 'name', sortOrder: 'asc', pageNumber: 1, pageSize: 50 })
            } else {
                dispatch(commonActions.setLoader(false))
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};


export const getFuelSurcharge = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('applicationSettings', 'fuelSurcharge') + '?sortColumn=' + inputDTO.sortColumn + ':' + inputDTO.sortOrder + '&pageSize=' + inputDTO.pageSize + '&pageNumber=' + inputDTO.pageNumber;
    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_FUELSURCHARGES, fuelSurchargeCount: response.data.content.totalResults, fuelSurchargeIndexList: response.data.content.fuelSurchargeIndexList })
                dispatch({type: type.SET_PAGING_SORTING, pageNumber: inputDTO.pageNumber, pageSize: inputDTO.pageSize, sortColumn: inputDTO.sortColumn, sortOrder: inputDTO.sortOrder})
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};


export const getFuelCostIndex = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('applicationSettings', 'fuelCostIndex') + '?fuelSurchargeId=' + inputDTO.fuelSurchargeId;
    dispatch(commonActions.setLoader(true))
    // dispatch({ type: type.SET_FUELCOSTINDEXES, fuelCostIndexes: [] })
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_FUELCOSTINDEXES, fuelCostIndexes: response.data.content.fuelCostIndexList })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};


export const updateFuelCostIndex = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('applicationSettings', 'fuelCostIndex');
    dispatch(commonActions.setLoader(true))
    axios['post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                if (inputDTO.id) {
                    utilCommon.showNotification('Fuel cost index updated succesfully.')
                } else {
                    utilCommon.showNotification('Fuel cost index added succesfully.')
                }
                getFuelCostIndex(dispatch, lang, dictionary, inputDTO)
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};


export const deleteFuelCostIndex = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('applicationSettings', 'fuelCostIndex');
    dispatch(commonActions.setLoader(true))
    let payload = { id: [inputDTO.id] }
    axios.patch(url, payload, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                getFuelCostIndex(dispatch, lang, dictionary, inputDTO)
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};



export const getTransitTime = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url
    if (inputDTO.pageSize) {
        url = getApiUrl('applicationSettings', 'transitTime') + '?sortColumn=' + inputDTO.sortColumn + ':' + inputDTO.sortOrder + '&pageSize=' + inputDTO.pageSize + '&pageNumber=' + inputDTO.pageNumber;
    } else {
        url = getApiUrl('applicationSettings', 'transitTime') + '?sortColumn=modifiedDate:DESC&pageSize=50&pageNumber=1&needCount=1';
    }
    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_TRANSITTIMES, transitTimes: response.data.content.transitTimeList, totalResults: response.data.content.totalResults })
                dispatch({type: type.SET_PAGING_SORTING, pageNumber: inputDTO.pageNumber, pageSize: inputDTO.pageSize, sortColumn: inputDTO.sortColumn, sortOrder: inputDTO.sortOrder})
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};


export const getGlobalDiscount = (dispatch, lang, dictionary) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('applicationSettings', 'globalDiscount');
    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_GLOBAL_DISCOUNT, globalDiscount: (response.data.content.globalDiscountList.length > 0 ? getParsedGlobalDiscount(response.data.content.globalDiscountList) : {}) })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const saveGlobalDiscount = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('applicationSettings', 'globalDiscount');
    dispatch(commonActions.setLoader(true))
    axios.put(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification('Global discounts updated succesfully.')
                getGlobalDiscount(dispatch, lang, dictionary)
                getCarrierMarginAdjustmentLogs(dispatch, lang, dictionary)
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

const getParsedGlobalDiscount = (discounts) => {
    let obj = {

    }
    discounts.map(x => {
        if (x.shippingFrequencyId == 1) {
            obj.discount1 = x.shippingDiscount
        }
        if (x.shippingFrequencyId == 2) {
            obj.discount2 = x.shippingDiscount
        }
        if (x.shippingFrequencyId == 3) {
            obj.discount3 = x.shippingDiscount
        }
        if (x.shippingFrequencyId == 4) {
            obj.discount4 = x.shippingDiscount
        }
        if (x.shippingFrequencyId == 5) {
            obj.discount5 = x.shippingDiscount
        }
        if (x.shippingFrequencyId == 6) {
            obj.discount6 = x.shippingDiscount
        }
    })

    return obj
}

export const getCarrierMarginAdjustments = (dispatch, lang, dictionary) => {
    const params = {headers: authHeader()};
    const url = getApiUrl('applicationSettings', 'carrierMarginAdjustments');

    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))

            if (response.data.success) {
                dispatch({type: type.SET_CARRIER_MARGIN_ADJUSTMENTS, carrierMarginAdjustments: getParsedCarrierMarginAdjustments(response.data.data)})
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const saveCarrierMarginAdjustments = (dispatch, lang, dictionary, inputDTO) => {
    const params = {headers: authHeader()};
    const url = getApiUrl('applicationSettings', 'carrierMarginAdjustments');

    dispatch(commonActions.setLoader(true))
    axios.post(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))

            if (response.data.success) {
                utilCommon.showNotification('Carrier margin adjustments updated successfully.')
                getCarrierMarginAdjustments(dispatch, lang, dictionary)
                getCarrierMarginAdjustmentLogs(dispatch, lang, dictionary)
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

const getParsedCarrierMarginAdjustments = (carrierMarginAdjustments) => {
    let obj = {}

    carrierMarginAdjustments.map(x => {
        lodash.set(obj, `decreaseMargin${x.shippingFrequency}`, Number(x.decreaseMargin))
        lodash.set(obj, `decreaseMarginFirstDay${x.shippingFrequency}`, x.decreaseMarginFirstDay)
        lodash.set(obj, `decreaseMarginLastDay${x.shippingFrequency}`, x.decreaseMarginLastDay)
        lodash.set(obj, `increaseMargin${x.shippingFrequency}`, Number(x.increaseMargin))
        lodash.set(obj, `increaseMarginFirstDay${x.shippingFrequency}`, x.increaseMarginFirstDay)
        lodash.set(obj, `increaseMarginLastDay${x.shippingFrequency}`, x.increaseMarginLastDay)
    })

    return obj
}

export const getCarrierMarginAdjustmentLogs = (dispatch, lang, dictionary) => {
    const params = {headers: authHeader()};
    const url = getApiUrl('applicationSettings', 'carrierMarginAdjustmentLogs');

    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))

            if (response.data.success) {
                dispatch({type: type.SET_CARRIER_MARGIN_ADJUSTMENT_LOGS, carrierMarginAdjustmentLogs: response.data.data})
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const getFacilityTypes = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    dispatch({ type: type.SET_CURRENT_PAGE, pageNumber:inputDTO.pageNumber});
    const url = getApiUrl('applicationSettings', 'loadingFacility') + '?sortColumn=' + inputDTO.sortColumn + ':' + inputDTO.sortOrder + '&pageSize=' + inputDTO.pageSize + '&pageNumber=' + inputDTO.pageNumber;
    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_FACILITYTYPES, facilityTypeCount: response.data.content.totalResults, facilityTypes: response.data.content.loadingFacilityList })
                dispatch({type: type.SET_PAGING_SORTING, pageNumber: inputDTO.pageNumber, pageSize: inputDTO.pageSize, sortColumn: inputDTO.sortColumn, sortOrder: inputDTO.sortOrder})
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const getAccessorials = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('applicationSettings', 'accessorials') + '?sortColumn=' + inputDTO.sortColumn + ':' + inputDTO.sortOrder + '&pageSize=' + inputDTO.pageSize + '&pageNumber=' + inputDTO.pageNumber;
    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_ACCESSORIALS, accessorialCount: response.data.content.totalResults, accessorials: response.data.content.accessorialList })
                dispatch({type: type.SET_PAGING_SORTING, pageNumber: inputDTO.pageNumber, pageSize: inputDTO.pageSize, sortColumn: inputDTO.sortColumn, sortOrder: inputDTO.sortOrder})
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};


export const getShipmentModeMaster = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('user', 'getMasterInfo');
    axios.get(url, params)
    .then((response) => {
      dispatch(commonActions.setLoader(false))
      if (response.data.success) {
        let shipmentMode = response.data.content.masterDataList.contractModeList
        dispatch({ type: type.SET_MODE, shipmentMode: shipmentMode })
      }
    })
    .catch((err) => {
      dispatch(commonActions.setLoader(false))
    });
};



export const updateAccessorials = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('applicationSettings', 'accessorials');
    dispatch(commonActions.setLoader(true))
    axios[inputDTO.id ? 'put' : 'post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                if (inputDTO.id) {
                    utilCommon.showNotification('Accessorial updated succesfully.')
                } else {
                    utilCommon.showNotification('Accessorial added succesfully.')
                }
                getAccessorials(dispatch, lang, dictionary, { pageNumber: 1, pageSize: 50, sortColumn: 'name', sortOrder: 'asc' })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};
export const bulkUpdateFacilityType = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('applicationSettings', 'bulkupdateFacilityTypes');
    dispatch(commonActions.setLoader(true))
    axios.put(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification('Facility Types updated succesfully.')
                dispatch({ type: type.SET_PAGING })
                dispatch({ type: type.SET_CURRENT_PAGE, pageNumber:inputDTO.updatePageNumber});
                getFacilityTypes(dispatch, lang, dictionary, { sortColumn: inputDTO.currentColumn, sortOrder:inputDTO.currentOrder, pageNumber: inputDTO.updatePageNumber, pageSize: utilCommon.getSession('pageSize')||50 })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};


export const getLoadingFacilityAccessorials = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('applicationSettings', 'loadingFacilityAccessorial') + '?id=' + inputDTO.id;
    dispatch(commonActions.setLoader(true))
    dispatch({ type: type.SET_FACILITY_TYPE_ACCESSORIALS, facilityAccessorials: [] })
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_FACILITY_TYPE_ACCESSORIALS, facilityAccessorials: response.data.content.accessorials })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};





export const addFacilityTypes = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('applicationSettings', 'loadingFacility');
    dispatch(commonActions.setLoader(true))
    axios[inputDTO.id ? 'put' : 'post'](url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                if (inputDTO.id) {
                    utilCommon.showNotification('Facility type updated succesfully.')
                } else {
                    utilCommon.showNotification('Facility type added succesfully.')
                }
                getFacilityTypes(dispatch, lang, dictionary, { sortColumn: 'name', sortOrder: 'asc', pageNumber: 1, pageSize: 50 })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });

}

export const deleteCouponCode = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('applicationSettings', 'manageCoupon');
    let payload = { couponId: inputDTO.couponId }
    dispatch(commonActions.setLoader(true))
    axios.patch(url, payload, params)
        .then((response) => {
            if (response.data.success) {
                utilCommon.showNotification('Coupon deleted succesfully.', dictionary, lang)
                dispatch({ type: type.SET_PAGING })
                getManageCoupon(dispatch, lang, dictionary, { sortColumn: 'couponCode', sortOrder: utilCommon.getSession('sortOrder')||'asc', pageNumber: utilCommon.getSession('pageNumber')||1, pageSize: utilCommon.getSession('pageSize')||50 })
            } else {
                dispatch(commonActions.setLoader(false))
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const addManageCoupon = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('applicationSettings', 'manageCoupon');
    dispatch(commonActions.setLoader(true))
    axios[inputDTO.couponId ? 'put' : 'post'](url, inputDTO, params)
        .then((response) => {
            if (response.data.success) {
                if (inputDTO.couponId) {
                    utilCommon.showNotification('Coupon updated succesfully.', dictionary, lang)
                } else {
                    utilCommon.showNotification('Coupon added succesfully.', dictionary, lang)
                }
                dispatch({ type: type.SET_PAGING })
                getManageCoupon(dispatch, lang, dictionary, { sortColumn: 'couponCode', sortOrder: utilCommon.getSession('sortOrder')||'asc', pageNumber: utilCommon.getSession('pageNumber')||1, pageSize: utilCommon.getSession('pageSize')||50 })
            } else {
                dispatch(commonActions.setLoader(false))
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const getManageCoupon = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('applicationSettings', 'manageCoupon') + '?sortColumn=' + inputDTO.sortColumn + '&sortOrder=' + inputDTO.sortOrder + '&pageSize=' + inputDTO.pageSize + '&pageNumber=' + inputDTO.pageNumber;
    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_MANAGE_COUPON, manageCouponList: response.data.content.couponCodeList, totalResults: response.data.content.count, url: url, })
                dispatch({type: type.SET_PAGING_SORTING, pageNumber: inputDTO.pageNumber, pageSize: inputDTO.pageSize, sortColumn: inputDTO.sortColumn, sortOrder: inputDTO.sortOrder})
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const getNotification = (dispatch, lang, dictionary) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('applicationSettings', 'systemWideNotification')
    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_SYSTEM_WIDE_NOTIFICATION, id: response.data.content.systemNotificationList.id, systemWideNotification: response.data.content.systemNotificationList, totalResults: response.data.content.totalResults })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const updateNotification = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('applicationSettings', 'systemWideNotification');
    dispatch(commonActions.setLoader(true))
    axios.put(url, inputDTO, params)
        .then((response) => {
            if (response.data.success) {
                if (inputDTO.id) {
                    utilCommon.showNotification('System wide notification updated succesfully.', dictionary, lang)
                } else {
                    utilCommon.showNotification('System wide notification not updated succesfully.', dictionary, lang)
                }
                getNotification(dispatch, lang, dictionary)
            } else {
                dispatch(commonActions.setLoader(false))
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const deleteTeams = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('applicationSettings', 'teams');
    let payload = { teamId: [inputDTO.teamId] }
    dispatch(commonActions.setLoader(true))
    axios.patch(url, payload, params)
        .then((response) => {
            if (response.data.success) {
                utilCommon.showNotification('Team deleted succesfully.', dictionary, lang)
                dispatch({ type: type.SET_PAGING })
                getTeams(dispatch, lang, dictionary, { sortColumn: 'teamName', sortOrder: 'asc', pageNumber: 1, pageSize: 50 })
            } else {
                dispatch(commonActions.setLoader(false))
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const addTeams = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('applicationSettings', 'teams');
    dispatch(commonActions.setLoader(true))
    axios[inputDTO.teamId ? 'put' : 'post'](url, inputDTO, params)
        .then((response) => {
            if (response.data.success) {
                if (inputDTO.teamId) {
                    utilCommon.showNotification('Team updated succesfully.', dictionary, lang)
                } else {
                    utilCommon.showNotification('Team added succesfully.', dictionary, lang)
                }
                dispatch({ type: type.SET_PAGING })
                getTeams(dispatch, lang, dictionary, { sortColumn: 'teamName', sortOrder: 'asc', pageNumber: 1, pageSize: 50 })
            } else {
                dispatch(commonActions.setLoader(false))
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const getTeams = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('applicationSettings', 'teams') + '?sortColumn=' + inputDTO.sortColumn + '&sortOrder=' + inputDTO.sortOrder + '&pageSize=' + inputDTO.pageSize + '&pageNumber=' + inputDTO.pageNumber;
    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_TEAMS, teams: response.data.content.teamsList, totalResults: response.data.content.count })
                dispatch({type: type.SET_PAGING_SORTING, pageNumber: inputDTO.pageNumber, pageSize: inputDTO.pageSize, sortColumn: inputDTO.sortColumn, sortOrder: inputDTO.sortOrder})
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};



export const getTeamUserCount = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('applicationSettings', 'teamUserCount') + '?teamUuid=' + inputDTO.teamUuid;
    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_TEAM_USER_COUNT, teamUserCount: response.data.content.teamUserList })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};


export const getMasterData = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('applicationSettings', 'getSupervisior');
    axios.get(url + "?status=1", params)
        .then((response) => {
            if (response.data.success) {
            dispatch({ type: type.SET_SUPERVISIORS, supervisiors: response.data.content.userList })
        }
    })
    .catch((err) => {
    });
}

export const addFalveyTermsConditions = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('applicationSettings', 'falveyTermsConditions');
    dispatch(commonActions.setLoader(true))
    axios[inputDTO.id ? 'put' : 'post'](url, inputDTO, params)
        .then((response) => {
            if (response.data.success) {
                if (inputDTO.id) {
                    utilCommon.showNotification('Falvey terms & conditions updated succesfully.', dictionary, lang)
                } else {
                    utilCommon.showNotification('Falvey terms & conditions added succesfully.', dictionary, lang)
                }
                dispatch({ type: type.SET_PAGING })
                getFalveyTermsConditions(dispatch, lang, dictionary, { sortColumn: 'addedDate', sortOrder: 'desc', pageNumber: 1, pageSize: 50 })
            } else {
                dispatch(commonActions.setLoader(false))
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const getFalveyTermsConditions = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('applicationSettings', 'falveyTermsConditions') + '?sortColumn=' + inputDTO.sortColumn + ':' + inputDTO.sortOrder + '&pageSize=' + inputDTO.pageSize + '&pageNumber=' + inputDTO.pageNumber;
    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_FALVEY_TERMS_CONDITIONS, falveyTermsConditionsList: response.data.content.falveyTermsConditionList, totalResults: response.data.content.totalResults })
                dispatch({type: type.SET_PAGING_SORTING, pageNumber: inputDTO.pageNumber, pageSize: inputDTO.pageSize, sortColumn: inputDTO.sortColumn, sortOrder: inputDTO.sortOrder})
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const addTMSTermsConditions = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('applicationSettings', 'tmsTermsConditions');
    dispatch(commonActions.setLoader(true))
    axios[inputDTO.id ? 'put' : 'post'](url, inputDTO, params)
        .then((response) => {
            if (response.data.success) {
                if (inputDTO.id) {
                    utilCommon.showNotification('TMS terms & conditions updated succesfully.', dictionary, lang)
                } else {
                    utilCommon.showNotification('TMS terms & conditions added succesfully.', dictionary, lang)
                }
                utilCommon.showNotification('Please update shipments by cron job!', dictionary, lang)
                dispatch({ type: type.SET_PAGING })
                getTMSTermsConditions(dispatch, lang, dictionary, { sortColumn: 'addedDate', sortOrder: 'desc', pageNumber: 1, pageSize: 50 })
            } else {
                dispatch(commonActions.setLoader(false))
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const getTMSTermsConditions = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('applicationSettings', 'tmsTermsConditions') + '?sortColumn=' + inputDTO.sortColumn + ':' + inputDTO.sortOrder + '&pageSize=' + inputDTO.pageSize + '&pageNumber=' + inputDTO.pageNumber;
    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_TMS_TERMS_CONDITIONS, tmsTermsConditionsList: response.data.content.fcTermsConditionList, totalResults: response.data.content.totalResults })
                dispatch({type: type.SET_PAGING_SORTING, pageNumber: inputDTO.pageNumber, pageSize: inputDTO.pageSize, sortColumn: inputDTO.sortColumn, sortOrder: inputDTO.sortOrder})
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
    });
};

export const getFinancialSettings = (dispatch, lang, dictionary) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('additionalSettings', 'financialSettings');
    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_FINANCIAL_SETTINGS, financialSettings: (response.data.content.financialSettingList.length > 0 ? getParsedFinancialSettings(response.data.content.financialSettingList) : {}) })
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const saveFinancialSettings = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('additionalSettings', 'financialSettings');
    dispatch(commonActions.setLoader(true))
    axios.put(url, inputDTO, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                utilCommon.showNotification('Financial settings updated succesfully.')
                getFinancialSettings(dispatch, lang, dictionary)
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};



const getParsedFinancialSettings = (settings) => {
    let obj = {}
    let entry = {}
    Object.entries(globals.FinancialSettingsMapping).forEach(([k, v]) => {
        entry = lodash.find(settings, {'id': v});
        obj[`${k}Id`] = v
        obj[`${k}`] = lodash.get(entry, 'financialSetting');
        obj[`${k}Name`] = lodash.get(entry, 'name');
        obj[`${k}Type`] = lodash.get(entry, 'type');
    })

    return obj
}


export const deleteIP = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('applicationSettings', 'ipAddress');
    let payload = { id: [inputDTO.id] }
    dispatch(commonActions.setLoader(true))
    axios.patch(url, payload, params)
        .then((response) => {
            if (response.data.success) {
                utilCommon.showNotification('IP address deleted succesfully.', dictionary, lang)
                dispatch({ type: type.SET_PAGING })
                getApprovedIP(dispatch, lang, dictionary, { sortColumn: 'ipAddress', sortOrder: 'asc', pageNumber: 1, pageSize: 50 })
            } else {
                dispatch(commonActions.setLoader(false))
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const addApprovedIP = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    const url = getApiUrl('applicationSettings', 'ipAddress');
    dispatch(commonActions.setLoader(true))
    axios[inputDTO.id ? 'put' : 'post'](url, inputDTO, params)
        .then((response) => {
            if (response.data.success) {
                if (inputDTO.id) {
                    utilCommon.showNotification('IP address updated succesfully.', dictionary, lang)
                } else {
                    utilCommon.showNotification('IP address added succesfully.', dictionary, lang)
                }
                utilCommon.removeSession('sortOrder');
                dispatch({ type: type.SET_PAGING })
                getApprovedIP(dispatch, lang, dictionary, { sortColumn: 'ipAddress', sortOrder: 'asc', pageNumber: 1, pageSize: 50 })
            } else {
                dispatch(commonActions.setLoader(false))
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
        });
};

export const getApprovedIP = (dispatch, lang, dictionary, inputDTO) => {
    const params = { headers: authHeader() };
    let url = getApiUrl('applicationSettings', 'ipAddress') + '?sortColumn=' + inputDTO.sortColumn + ':' + inputDTO.sortOrder + '&pageSize=' + inputDTO.pageSize + '&pageNumber=' + inputDTO.pageNumber;
    dispatch(commonActions.setLoader(true))
    axios.get(url, params)
        .then((response) => {
            dispatch(commonActions.setLoader(false))
            if (response.data.success) {
                dispatch({ type: type.SET_APPROVED_IP, approvedIPList: response.data.content.ipAddressList, totalResults: response.data.content.totalResults })
                dispatch({type: type.SET_PAGING_SORTING, pageNumber: inputDTO.pageNumber, pageSize: inputDTO.pageSize, sortColumn: inputDTO.sortColumn, sortOrder: inputDTO.sortOrder})
            } else {
                response.data.errors.map((error) => {
                    utilCommon.showNotification(error, dictionary, lang)
                })
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false))
    });
};

export default {
    addTransitTime,
    addFuelSurcharge,
    getTransitTime,
    getGlobalDiscount,
    getFuelSurcharge,
    getFuelCostIndex,
    deleteFuelSurcharge,
    saveGlobalDiscount,
    updateFuelCostIndex,
    deleteFuelCostIndex,
    getFacilityTypes,
    addFacilityTypes,
    getAccessorials,
    getLoadingFacilityAccessorials,
    updateAccessorials,
    bulkUpdateFacilityType,
    getManageCoupon,
    addManageCoupon,
    deleteCouponCode,
    getShipmentModeMaster,
    updateNotification,
    getNotification,
    getTeams,
    addTeams,
    deleteTeams,
    getTeamUserCount,
    addFalveyTermsConditions,
    getFalveyTermsConditions,
    addTMSTermsConditions,
    getTMSTermsConditions,
    getMasterData,
    getFinancialSettings,
    saveFinancialSettings,
    getApprovedIP,
    addApprovedIP,
    deleteIP,
    getCarrierMarginAdjustments,
    saveCarrierMarginAdjustments,
    getCarrierMarginAdjustmentLogs
};


